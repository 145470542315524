import Link from 'components/links/Link';
import * as React from 'react';

import pages from '../../config/pages';

function SvgComponent(props) {
    return (
        <svg width="100%" height={279} viewBox="0 0 364 279" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M267.543 10.336c-5.171-.478-11.049-1.1-17.393-1.77l-20.615-.203c-3.978.13-7.21.236-9.439.236-7.662 0-19.587.48-28.353.831-4.188.168-7.656.308-9.591.352-10.5.24-16.447-.226-20.276-1.014l-57.931-5.17a86.801 86.801 0 00-6.36-1.017A79.016 79.016 0 0088.075 2C40.784 2 2.447 31.886 2.447 105.425c-1.256 21.925 0 69.575 5.5 92.075 8.5 19.5 24.247 20.263 38.785 31.52-17.285 25.48 4.648 51.423 6.215 47.48 14.5-36.48 83.5-35.5 95-38.5h20c19.5-4.5 28.5-8.98 45.399-7.676 1.033-.071 42.852-3.329 43.8-3.395 6.282-.439 57.432-.759 42.223-.893 47.278-1.334 63.683-66.432 62.578-123.536 0-23.647-2.901-65.982-27.517-83.802-19.693-14.255-44.813-7.168-66.887-8.362z"
                fill="#fff"
                fillOpacity={0.2}
                stroke="#fff"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <text
                fill="#414142"
                style={{
                    whiteSpace: 'pre'
                }}
                fontFamily="Roboto"
                fontSize={16}
                letterSpacing=".02em"
            >
                <tspan x={47.447} y={91.469}>
                    {'Na voljo smo tudi za pohvale, za '}
                </tspan>
                <tspan x={47.447} y={113.469}>
                    {'slovni\u010Dne popravke in za kritike. Ali pa '}
                </tspan>
                <tspan x={47.447} y={135.469}>
                    {'za samoizpovedi. Lahko z nami deli\u0161 '}
                </tspan>
                <tspan x={47.447} y={157.469}>
                    {'nasvet svoje babice in dedka. Ali pa za '}
                </tspan>
                <tspan x={47.447} y={179.469}>
                    {'skupno kavo. Pa tudi za vpra\u0161anja.'}
                </tspan>
            </text>

            <Link to={pages.contact.url}>
                <path
                    d="M186.447 227c0-11.046 8.955-20 20-20h139v20c0 11.046-8.954 20-20 20h-119c-11.045 0-20-8.954-20-20z"
                    fill="#414142"
                />
                <text
                    fill="#fff"
                    style={{
                        whiteSpace: 'pre'
                    }}
                    fontFamily="Roboto"
                    fontSize={14}
                    fontWeight="bold"
                    letterSpacing=".05em"
                >
                    <tspan x={220.543} y={232.285}>
                        {'PI\u0160I NAM'}
                    </tspan>
                </text>
                <path
                    d="M319.247 221l6.2 6.3m0 0l-6.2 6.2m6.2-6.2h-16"
                    stroke="#fff"
                    strokeWidth={2}
                    strokeMiterlimit={10}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </Link>

            <text
                fill="#fff"
                style={{
                    whiteSpace: 'pre'
                }}
                fontFamily="Paytone One"
                fontSize={30}
                letterSpacing="0em"
            >
                <tspan x={47.447} y={58.95}>
                    KONTAKTIRAJ NAS
                </tspan>
            </text>
        </svg>
    );
}

export default SvgComponent;
