import Link from 'components/links/Link';
import * as React from 'react';

import pages from '../../config/pages';

function SvgComponent(props) {
    return (
        <svg width={525} height={260} viewBox="0 0 525 260" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M399.359 10.866c-7.192-.508-15.366-1.17-24.189-1.883l-28.67-.215c-5.532.138-10.027.25-13.127.25-10.656 0-27.241.51-39.431.884-5.826.18-10.648.327-13.339.374-14.602.255-22.873-.24-28.199-1.078L171.839 3.7a155.465 155.465 0 00-8.844-1.08A143.307 143.307 0 00149.766 2C83.998 2 30.683 33.786 30.683 111.998c0 22.482 5.113 33.111 15.266 48.747C55.202 179.235 2 183.663 2 183.663s9.715 25.886 62.454 18.028C71.394 206.738 81.571 253 121.82 253c18.086 0 91.255-9.036 101.315-8.164l34.88-1.387 3.058-.169 62.913 1.556c1.437-.076 59.595-3.54 60.914-3.611 8.737-.466 79.872-.807 58.721-.949 65.75-1.419 80.796-70.38 79.26-131.114 0-25.15 3.734-70.45-30.5-89.403-27.387-15.161-62.322-7.623-93.021-8.893z"
                fill="#fff"
                fillOpacity={0.2}
                stroke="#fff"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <text
                fill="#414142"
                style={{
                    whiteSpace: 'pre'
                }}
                fontFamily="Roboto"
                fontSize={16}
                letterSpacing=".02em"
            >
                <tspan x={103} y={109.469}>
                    {'Na voljo smo tudi za pohvale, za slovni\u010Dne '}
                </tspan>
                <tspan x={103} y={131.469}>
                    {'popravke in za kritike. Ali pa za samoizpovedi. '}
                </tspan>
                <tspan x={103} y={153.469}>
                    {'Lahko z nami deli\u0161 nasvet svoje babice in dedka. '}
                </tspan>
                <tspan x={103} y={175.469}>
                    {'Ali pa za skupno kavo. Pa tudi za vpra\u0161anja.'}
                </tspan>
            </text>
            <text
                fill="#fff"
                style={{
                    whiteSpace: 'pre'
                }}
                fontFamily="Paytone One"
                fontSize={30}
                letterSpacing="0em"
            >
                <tspan x={103} y={76.95}>
                    KONTAKTIRAJ NAS
                </tspan>
            </text>
            <Link to={pages.contact.url}>
                <path
                    d="M297 240c0-11.046 8.954-20 20-20h139v20c0 11.046-8.954 20-20 20H317c-11.046 0-20-8.954-20-20z"
                    fill="#414142"
                />
                <text
                    fill="#fff"
                    style={{
                        whiteSpace: 'pre'
                    }}
                    fontFamily="Roboto"
                    fontSize={14}
                    fontWeight="bold"
                    letterSpacing=".05em"
                >
                    <tspan x={331.096} y={245.285}>
                        {'PI\u0160I NAM'}
                    </tspan>
                </text>
                <path
                    d="M429.8 234l6.2 6.3m0 0l-6.2 6.2m6.2-6.2h-16"
                    stroke="#fff"
                    strokeWidth={2}
                    strokeMiterlimit={10}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </Link>
        </svg>
    );
}

export default SvgComponent;
