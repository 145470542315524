import React from 'react';

import FacebookIcon from '/public/images/icons/facebook.svg';
import InstagramIcon from '/public/images/icons/instagram.svg';
import YoutubeIcon from '/public/images/icons/youtube.svg';

import socials from '../../config/socials';
import IconButton from '../buttons/IconButton';

function SocialIcons({ styles }) {
    const appliedStyles = `socials flex ${styles}`;

    return (
        <div className={appliedStyles}>
            <a
                href={socials.instagram}
                className="mx-1 hover:text-brand transition duration-60 text-3xl"
                target="_blank"
                rel="noreferrer"
            >
                <IconButton>
                    <InstagramIcon />
                </IconButton>
            </a>
            <a
                href={socials.facebook}
                className="mx-1 hover:text-brand transition duration-60"
                target="_blank"
                rel="noreferrer"
            >
                <IconButton>
                    <FacebookIcon />
                </IconButton>
            </a>
            <a
                href={socials.youtube}
                className="mx-1 hover:text-brand transition duration-60"
                target="_blank"
                rel="noreferrer"
            >
                <IconButton>
                    <YoutubeIcon />
                </IconButton>
            </a>
        </div>
    );
}

export default SocialIcons;
