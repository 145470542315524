import Link from 'components/links/Link';
import { links } from 'components/navigation/MainMenu';
import React from 'react';

import FooterCurveSvg from './curves/svg/FooterCurveSvg';
import FooterThinCurveSvg from './curves/svg/FooterThinCurveSvg';
import SocialIcons from './icons/SocialIcons';
import ContactBallonSvg from './svg/ContactBallonSvg';
import ContactBalloonMobileSvg from './svg/ContactBalloonMobileSvg';
import documents from '../config/documents';
import pages from '../config/pages';

const footerLinks = [
    ...links,
    {
        label: 'O projektu',
        href: pages.about.url
    },
    {
        label: 'Kontakt',
        href: pages.contact.url
    }
];

function Footer() {
    return (
        <footer className="bg-gradient-to-b from-[#FBAC19] to-[#F68920]">
            <div className="relative -top-4">
                <FooterCurveSvg />
            </div>

            <div className="container">
                <div className="grid grid-cols-12 gap-6 xl:gap-12 items-center">
                    <div className="col-span-12 md:col-span-8 xl:col-span-6">
                        <div className="lg:flex items-center">
                            <div className="lg:hidden w-full">
                                <ContactBalloonMobileSvg />
                            </div>
                            <img
                                src="/images/logo-peter.svg"
                                alt=""
                                className="h-36 md:h-auto ml-12 xs:ml-24 sm:ml-44 -mt-14 md:-mt-8 lg:mt-0"
                            />
                            <div className="hidden lg:block w-full">
                                <ContactBallonSvg className="max-w-full" />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-4 xl:col-span-6">
                        <div className="xl:flex xl:space-x-8">
                            <div>
                                <h4 className="text-white font-serif">Strani</h4>
                                <nav className="space-y-2 mt-2">
                                    {footerLinks.map((link, i) => (
                                        <div key={i} className="space-y-2">
                                            <Link
                                                to={link.href}
                                                styles="px-4 py-2 pl-0 font-medium tracking-wide transition hover:text-white duration-60"
                                            >
                                                {link.label}
                                            </Link>
                                        </div>
                                    ))}
                                </nav>
                            </div>
                            <div>
                                <h4 className=" text-white font-serif mt-4 xl:mt-0 mb-1 leading-tight">
                                    KZV na družbenih omrežjih
                                </h4>
                                <div className="-ml-3">
                                    <SocialIcons />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="divider py-4 mt-8">
                <FooterThinCurveSvg />
            </div>
            <div className="container legal pb-6 text-sm">
                <div className="flex justify-center space-x-8">
                    <a className="hover:underline" href={documents.privacy} target="_blank" rel="noreferrer">
                        Politika zasebnosti
                    </a>
                    <a className="hover:underline" href={documents.terms} target="_blank" rel="noreferrer">
                        Splošni pogoji
                    </a>
                    <Link styles="hover:underline" to={pages.cookies.url}>
                        Piškotki
                    </Link>
                </div>
            </div>
            <div className="container legal pb-4 text-xs text-white">
                <div className="flex justify-center space-x-8">
                    <Link className="hover:underline" to="/privacy-policy">
                        Privacy policy
                    </Link>
                    <Link className="hover:underline" to="/terms-of-service">
                        Terms of service
                    </Link>
                    <Link styles="hover:underline" to="/cookie-policy">
                        Cookie policy
                    </Link>
                </div>
            </div>
            <p className="mt-2 text-center p-2 text-xs text-gray-200">
                &copy; {new Date().getFullYear()} HUDDA, humane digitalne rešitve, d.o.o. <br /> Vse pravice pridržane.
            </p>
        </footer>
    );
}

export default Footer;
