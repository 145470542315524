import * as React from 'react';

function FooterCurveSvg(props) {
    return (
        <svg
            width="100%"
            height={34}
            viewBox="0 0 1293 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            {...props}
        >
            <path
                d="M11 18.27c41.322-7.497 81.16-8.946 122.468-8.023 33.656.752 67.224 3.558 100.791 4.012 28.31.383 56.425 4.012 84.752 4.012 35.323 0 70.333-6.997 105.561-6.018 23.785.661 47.733 5.445 71.746 5.906 25.783.496 51.593.112 77.382.112 19.947 0 39.672 2.005 59.608 2.005 21.945 0 43.436-4.901 65.352-3.9 81.507 3.725 164.151.008 245.802-2.34 46.808-1.346 93.308-2.242 140.138-1.783 31.82.312 63.76 3.775 95.59 4.903 23.23.824 48.48-2.16 71.52 1.226 6.33.928 11.04 3.9 17.56 3.9 3.64 0 19.22 3.865 11.71 0"
                stroke="#FBA919"
                strokeWidth={20}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default FooterCurveSvg;
