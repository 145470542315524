import * as React from 'react';

function FooterThinCurveSvg(props) {
    return (
        <svg width="100%" height={12} viewBox="0 0 1150 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M4.994 9.039c75.174 1.342 150.447 2.684 225.949 2.925 37.456.117 74.946-.04 112.337-.606 37.456-.567 74.619-1.648 111.814-2.69 36.836-1.036 73.736-1.915 110.899-2.371 37.62-.463 75.338-.6 113.056-.645 75.077-.078 150.284.234 225.23-.814 70.926-.99 143.481-3.446 214.181-.743 8.63.332 17.13.743 25.56 1.231 6.86.398 8.11-2.488 2.22-2.826-69.26-4-141.78-2.293-212.349-1.062-74.88 1.302-149.859 1.27-224.935 1.276-75.012.007-149.925.287-224.446 2.222-37.162.964-74.161 2.155-111.389 2.95-37.62.801-75.436 1.16-113.252 1.192-75.894.072-151.625-1.127-227.159-2.455-9.348-.163-18.695-.333-28.043-.502-6.308-.11-6.243 2.8.327 2.918z"
                fill="#fff"
                fillOpacity={0.3}
            />
        </svg>
    );
}

export default FooterThinCurveSvg;
